<template>
  <header>
    <div class="interior">
        <img src="@/assets/logo.png">
        <a href="#apply-form" class="btn btn-default header-btn">业务咨询</a>
    </div>
  </header>
</template>

<script>

export default {

}
</script>

<style lang="less" scoped>
header {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 60px;
  padding: 10px 100px;
  -moz-box-shadow: 0 3px 6px #838383;
  -webkit-box-shadow: 0 3px 6px #838383;
  box-shadow: 0 3px 6px #838383;
  z-index: 100;
}
header img {
  float: left;
  max-width: 96%;
}
header a {
  float: right;
}
header a.btn {
  padding: 6px 20px;
  font-size: 14px;
}
header a.btn:hover,
header a.btn:active {
  background-color: #333333;
  color: #ffffff;
}

.btn-default{
  color: #333;
    background-color: #fff;
    border-color: #ccc;
}

</style>
