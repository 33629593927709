<template>
  <div>
    <div class="part" v-for="( part , index ) in parts" :key="part.id" :class="'part-'+part.id" :style="{p1:index===1}">
      <div v-if="(part.id<8)" class="interior">
        <h2 v-if="part.h2">{{ part.h2 }}</h2>
        <p v-if="part.p1">{{ part.p1 }}</p>
        <p v-if="part.p2">{{ part.p2 }}</p>
        <img v-if="part.img_src" :src="part.img_src">
      </div>

      <a v-if="(8===part.id)" name="apply"></a>
      <div v-if="(8===part.id)" class="interior">
        <div name="apply" id="apply-form" action="" accept-charset="UTF-8" method="post" class="has-validation-callback">
          <h3>速签云业务咨询</h3>
          <p><input type="text" id="name" v-model.trim="name" placeholder="姓名" @blur="setTimeout(validateName(name),300)"></p>
          <p><input type="text" id="mobile" v-model.number="mobile" placeholder="手机" @blur="setTimeout(validatePhone(mobile),300)" ></p>
          <p><input type="text" id="email" v-model.trim="email" placeholder="邮箱" @blur="setTimeout(validateEMail(email),300)"></p>
          <p><button id="submit-button" type="submit" class="btn" @click="commit">提交</button></p>
          <div id="submit_msg">{{ submit_msg }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      name: '',
      mobile: '',
      email: '',
      submit_msg: '',
      parts: []
    }
  },
  created () {
    this.parts = [
      { id: 1, h2: '快速电子协议操作云平台', p1: '专业电子协议服务系统', p2: '经验丰富的技术团队打造' },
      { id: 2, h2: '可以带来客户的操作系统', p1: '直接对接各大银行，企业渠道', p2: '基于自身处理能力，选择客户进行接单' },
      { id: 3, h2: '免人工材料制作', p1: '80%材料系统自动生成', p2: '操作人员成本大幅降低，新人30分钟即可上岗', img_src: require('@/assets/fp_3.png') },
      { id: 4, h2: '办理进度自动通知', p1: '打造极致客户体验，流转进度自动通知，开关控制随心所欲', img_src: require('@/assets/fp_4.png') },
      { id: 5, h2: '数据云存储', p1: '客户数据永久保存', p2: '随时随地快捷查询' },
      { id: 6, h2: '智能统计报表', p1: '每日数据智能分析，员工业绩一目了然，各种指标手机自动推送', p2: '经验丰富的技术团队打造', img_src: require('@/assets/fp_6.png') },
      { id: 7, h2: '战略合作伙伴', p1: '通过智骐云签处理的电子协议每年超过100万本', img_src: require('@/assets/fp_7.png') },
      { id: 8 }
    ]
  },
  methods: {
    async commit () {
      if (this.validateName(this.name) && this.validatePhone(this.mobile) && this.validateEMail(this.email)) {
        const ret = await axios.post('/user', {
          name: this.name,
          mobile: this.mobile,
          email: this.email
        })
        console.log(ret.data)
        alert(ret.data.msg)
      }
    },
    submitMsg (msg) {
      console.log(msg)
      this.submit_msg = msg
    },
    validateName (value) {
      if (value === '' || value === undefined || value === null) {
        this.submitMsg('姓名不能为空')
        return false
      }
      return true
    },
    validatePhone (value) {
      const reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/
      if (value === '' || value === undefined || value === null) {
        this.submitMsg('请输入正确的电话号码或者固话号码')
        return false
      } else {
        if ((!reg.test(value)) && value !== '') {
          this.submitMsg('请输入正确的电话号码或者固话号码')
          return false
        } else {
          this.submitMsg('')
          return true
        }
      }
    },
    validateEMail (value) {
      // eslint-disable-next-line no-useless-escape
      const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/
      if (value === '' || value === undefined || value == null) {
        this.submitMsg('请输入正确的邮箱')
        return false
      } else {
        if (!reg.test(value)) {
          this.submitMsg('请输入正确的邮箱')
          return false
        } else {
          return true
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>

input:-internal-autofill-selected {
    box-shadow: inset 0 0 0 1000px #494b57 !important; // 改变了背景色
    -webkit-text-fill-color: #ffffff; // 改变了字体颜色
}

.interior {
  max-width: 1098px;
  width: 100%;
  margin: 0 auto;
}

.part {
  text-align: center;
  height: 578px;
  margin: 0;
  padding: 0;
  color: #ffffff;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
}
.part h2 {
  font-size: 48px;
  margin: 0 0 20px 0;
  padding-top: 80px;
  font-weight: 100;
}
.part p {
  font-size: 18px;
  font-weight: 100;
}
.part img {
  max-width: 100%;
  padding-top: 40px;
}
.part.part-1 {
  background-image: url(../assets/bg_1.jpeg);
  margin-top: 60px;
}
.part.part-1 h2 {
  padding-top: 180px;
}
.part.part-2 {
  background-image: url(../assets/bg_2.png);
}
.part.part-2 h2 {
  padding-top: 100px;
  color: #2b2b35;
}
.part.part-2 p {
  color: #2b2b35;
}
.part.part-3 {
  background-image: url(../assets/bg_3.png);
}
.part.part-4 {
  background-image: url(../assets/bg_4.png);
}
.part.part-4 h2,
.part.part-4 p {
  color: #2b2b35;
}
.part.part-5 {
  background-image: url(../assets/bg_5.png);
}
.part.part-5 h2 {
  padding-top: 180px;
}
.part.part-6 {
  background-image: url(../assets/bg_6.png);
}
.part.part-6 h2 {
  padding-top: 30px;
  color: #2b2b35;
}
.part.part-6 p {
  color: #2b2b35;
}
.part.part-6 img {
  padding: 0;
}
.part.part-7 {
  background-image: url(../assets/bg_7.jpeg);
}
.part.part-7 h2 {
  padding-top: 130px;
  color: #2b2b35;
}
.part.part-7 p {
  color: #2b2b35;
}
.part.part-7 img {
  padding: 130px 0 0 0;
}
.part.part-8 {
  background-image: url(../assets/bg_8.png);
}
.part.part-8 .interior {
  width: 90%;
  min-width: 300px;
  max-width: 400px;
}
.part.part-8 .interior h3 {
  font-size: 34px;
  color: #18fbc4;
  padding: 100px 0 40px;
  margin: 0;
}
.part.part-8 .interior p {
  position: relative;
  margin: 0 0 25px;
}
.part.part-8 .interior p input[type="text"] {
  border: 1px solid #18fbc4;
  border-radius: 5px;
  background-color: #494b57;
  color: #ffffff;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: 100;
  padding: 0 10px;
}

#submit-button {
  background: #18fbc4;
  padding: 6px 40px;
  font-size: 20px;
  color: #2b2b35;
}
#submit-button:hover,
#submit-button:active {
  background-color: #21937c;
  color: #ffffff;
}
</style>
