<template>
  <div id="main">
    <div id="container">
      <SQYHeader></SQYHeader>
      <SQYPart></SQYPart>
      <SQYFooter></SQYFooter>
    </div>
  </div>
</template>

<script>
import SQYHeader from './components/SQYHeader.vue'
import SQYPart from './components/SQYPart.vue'
import SQYFooter from './components/SQYFooter.vue'

export default {
  name: 'App',
  components: {
    SQYHeader,
    SQYPart,
    SQYFooter
  }
}
</script>

<style lang="less">
@charset "UTF-8";
@font-face {
  font-family: 'FZLanTingHeiS-EL-GB';
  src: url("./assets/P0UX9ws.eot");
  src: local(FZLanTingHeiS-EL-GB), url("./assets/P0UX9ws.eot?#iefix") format("embedded-opentype"), url("./assets/vNpaSZJ.woff") format("woff"), url("./assets/zv3Il.ttf") format("truetype"), url("./assets/4bIXy3V.svg") format("svg");
}
html,
body {
  height: 100%;
  color: #2b2b35;
  background-color: #ffffff;
  font-size: 12px;
  font-family: "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
}
iframe {
  width: 0;
  height: 0;
  display: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.clearfix {
  clear: both;
  content: "";
}
#main {
  width: 100%;
  overflow: hidden;
}
#main #container {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
}
</style>
