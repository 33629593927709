<template>
  <footer>
    <div class="interior">
      <div class="row-1">
        <img width="110" src="@/assets/zhushou2016.png"> <span>有任何问题，欢迎微信联系智骐云签小助手<br>微信号：chunyuzhou_weixin</span>
      </div>
      <p><img src="@/assets/footer_line.png"></p>
      <div class="row-2">©版权所有 智骐云签 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #66656b">鄂ICP备14005743号-2</a></div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
footer {
  background-color: #333541;
  color: #ffffff;
}
.interior {
  max-width: 1098px;
  width: 100%;
  margin: 0 auto;
}

footer .row-1 {
  width: 96%;
  max-width: 430px;
  margin: 0 auto;
  padding: 20px 0 10px;
  display: table;
}
footer .row-1 img {
  display: table-cell;
  max-width: 100%;
  padding-right: 10px;
}
footer .row-1 span {
  display: table-cell;
  font-size: 16px;
  padding-top: 30px;
  vertical-align: top;
}
footer .row-2 {
  color: #66656b;
  font-size: 14px;
  padding: 0 0 20px;
  text-align: center;
}
@media screen and (max-width: 420px) {
  header img {
    padding: 5px 0 0 5px;
  }
  header a {
    display: none;
  }
  .part h2 {
      font-size: 26px;
  }
  .part p {
      font-size: 13px;
  }
  .part img {
      max-width: 90%;
  }
  footer .row-1 span {
      padding: 0;
  }
  footer p img {
      width: 100%;
  }
}
</style>
